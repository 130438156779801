import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import firebase from 'firebase';
import * as ROUTES from '../../constants/routes';
import {AppString} from '../chatConstants.js'

const config = {
  apiKey: "AIzaSyC6R9KG9wet6xHY0nruH2nbZp1RrNJsLWw",
  authDomain: "collegiate-tutoring-network.firebaseapp.com",
  databaseURL: "https://collegiate-tutoring-network.firebaseio.com",
  projectId: "collegiate-tutoring-network",
  storageBucket: "collegiate-tutoring-network.appspot.com",
  messagingSenderId: "836198158249",
  appId: "1:836198158249:web:baca53375868094d47feb8",
  measurementId: "G-TGHJ1MZLML"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();

    //added by me
    this.myFirestore = app.firestore();

    this.myFirebase = app
    this.myStorage = app.storage()

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  //added by me
  getFirestore(){
    return this.myFirestore;
  }
  getFirebase(){
    return this.myFirebase;
  }

  addNewStudentAccount = (email, username, parentOrStudent, subjects, medium, location, subscriptionLength, subscriptionEndDate, subscriptionStartDate, source) => {
    app.firestore()
    .collection('students')
        .doc(email)
        .set({
            name: username,
            id: username,
        })
        .then(data => {
            localStorage.setItem(AppString.ID, username)
            localStorage.setItem(AppString.NAME, username)
            localStorage.setItem(AppString.EMAIL, email)
            localStorage.setItem(AppString.SUBSCRIPTION_START_DATE, subscriptionStartDate)
        });

    app.firestore()
    .collection('users')
        .doc(email)
        .set({
            name: username,
            id: username,
            account: "student",
            parent_student: parentOrStudent,
            subject_list: subjects,
            medium: medium, 
            location: location,
            subscriptionLength: subscriptionLength,
            subscriptionEndDate: subscriptionEndDate,
            subscriptionStartDate: subscriptionStartDate,
            howDidYouFindUs: source,
        })
        .then(data => {
            localStorage.setItem(AppString.ACCOUNT_TYPE, "student")
            localStorage.setItem(AppString.SUBSCRIPTION_LENGTH, subscriptionLength)
            localStorage.setItem(AppString.SUBSCRIPTION_END_DATE, subscriptionEndDate)
        });
    }

  addNewTutorAccount = (email, username) => {
    app.firestore()
    .collection('tutors')
        .doc(email)
        .set({
            name: username,
            id: username,
        })
        .then(data => {
            localStorage.setItem(AppString.ID, username)
            localStorage.setItem(AppString.NAME, username)
            localStorage.setItem(AppString.EMAIL, email)
        });
        
    app.firestore()
    .collection('users')
        .doc(email)
        .set({
            name: username,
            id: username,
            account: "tutor",
        })
        .then(data => {
            localStorage.setItem(AppString.ACCOUNT_TYPE, "tutor")
        });
  }

  createChat = (currentEmail, otherEmail, groupChatId, currentUserName, otherName) => {
    console.log("creating chat");
    const ref = app.firestore().collection(`users`).doc(currentEmail).collection('chats').doc(groupChatId);

    ref
    .get()
    .then(doc => {
      console.log("Exists?" + doc.exists); //true
      if(!doc.exists){
        console.log("chat does not exist")
        app.firestore()
          .collection('users')
              .doc(currentEmail)
              .collection('chats')
              .doc(groupChatId)
              .set({
                  otherPerson: otherName,
                  mostRecentMessage: null, 
                  lastReadMessage: null,
                  otherPersonEmail: otherEmail,
                  unseen: false,
              });

        app.firestore()
        .collection('users')
            .doc(otherEmail)
            .collection('chats')
            .doc(groupChatId)
            .set({
                otherPerson: currentUserName,
                mostRecentMessage: null, 
                lastReadMessage: null,
                otherPersonEmail: currentEmail,
                unseen: false,
            });
      }
      else {
          console.log("chat exists")
      }
    })
  }

  setID = (email) => 
    app.firestore().collection("users")
    .doc(email)
    .get()
    .then(doc => {
      localStorage.setItem(AppString.ID, doc.data().name)
      localStorage.setItem(AppString.NAME, doc.data().name)
      localStorage.setItem(AppString.ACCOUNT_TYPE, doc.data().account)
      localStorage.setItem(AppString.EMAIL, email)
      console.log( doc.data().name);
      //this.setState({ post_user_name: doc.data().name });
  });

  setAccountDetails = (email) => 
    app.firestore().collection("users")
      .doc(email)
      .get()
      .then(doc => {
        localStorage.setItem(AppString.SUBSCRIPTION_LENGTH, doc.data().subscriptionLength)
        localStorage.setItem(AppString.SUBSCRIPTION_END_DATE, doc.data().subscriptionEndDate)
        localStorage.setItem(AppString.SUBSCRIPTION_START_DATE, doc.data().subscriptionStartDate)
  });

  setUnseen = (otherUserEmail, groupChatId, otherUserName) =>
    app.firestore()
    .collection('users')
        .doc(otherUserEmail)
        .collection('chats')
        .doc(groupChatId)
        .update({
            unseen: true
    });

  setSeen = (email, groupChatId) =>
  app.firestore()
  .collection('users')
      .doc(email)
      .collection('chats')
      .doc(groupChatId)
      .update({
          unseen: false
  });


  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => {
    this.auth.signOut();
    localStorage.clear();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');
}

export default Firebase;
