import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import './Navigation.css';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);


const NavigationAuth = ({ authUser }) => (
  <div className="master">
    <div>
      <Link to="/">
        <img className="logo_new" src={require("./logo_dec2.png")} alt=""></img>
      </Link>
    </div>
    <div>
      <Link className="link" to={ROUTES.TUTORS}>Tutors</Link>
      <Link className="link" to={ROUTES.HOW_IT_WORKS}>How It Works</Link>    
      <Link className="link" to={ROUTES.ACCOUNT}>Account</Link>
        {!!authUser.roles[ROLES.ADMIN] && (
            <Link className="link" to={ROUTES.ADMIN}>Admin</Link>
        )}
      <Link className="link" to={ROUTES.CHAT}>Chat</Link>  
      <Link className="link" to={ROUTES.BLOG}>Blog</Link>
      <Link className="link" to={ROUTES.LANDING}>
      <SignOutButton className="signout_button" />
      </Link>
    </div>
  </div>
);

const NavigationNonAuth = () => (
  <div className="master"> 
    <div>
      <Link to="/">
        <img className="logo_new" src={require("./logo_dec2.png")} alt=""></img>
      </Link>
    </div>
    <div>
      <Link className="link" to={ROUTES.TUTORS}>Tutors</Link>
      <Link className="link" to={ROUTES.HOW_IT_WORKS}>How It Works</Link>
      <Link className="link" to={ROUTES.BLOG}>Blog</Link>
      <Link className="link" to={ROUTES.SIGN_IN}>Sign In</Link>
    </div>
  </div>
);

export default Navigation;
