import React from 'react';
import './UnderConstruction.css';



class UnderConstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //statesList: window.cs142models.statesModel(),
      returnStates: [],
      substring: '',
      inputValue: '',
      i: 100,
      r: 100,
      message: "Here is the alphabetically sorted list of all the ",
      buttonWasClicked: '',
    };
    this.init();
    this.handleChangeBoundNew = event => this.handleChangeMotto(event);
    this.handleChangeMotto = this.handleChangeMotto.bind(this);

    //console.log('window.cs142models.statesModel()', window.cs142models.statesModel());
  }

  init(){
    var tempArray = [];
    //tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
    var newTemp = this.state.returnStates;
    for(var i = 0; i < tempArray.length; i++){
      newTemp[i] = <li key={i}> {tempArray[i]} </li>;
      console.log('newTemp[i]', newTemp[i]);
      //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      //this.setState({returnStates: [...newTemp, <li key={i}> {tempArray[i]} </li>]});
      //this.setState({returnStates: this.state.returnStates.concat([<li key={i}> {tempArray[i]} </li>])})
    }
    this.setState({ returnStates: newTemp });
     console.log('newTemp', newTemp);
      console.log('userNamesList', this.state.returnStates);
  }

  handleChangeMotto(event){
    var substr = event.target.value;
    this.setState({ substring: event.target.value, i: this.state.statesList.length, r:this.state.returnStates.length });

    if(substr === ""){
      this.setState({ message: "Here is the alphabetically sorted list of all the" });
      //this.state.message = "Here is the alphabetically sorted list of all the ";
      var tempArray = [];
      var newTemp = this.state.returnStates;
      tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
      for(var i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });

    }
    else{
      //var emptyArr = [];
      //this.setState({ returnStates: emptyArr });
      //this.state.returnStates = [];
      tempArray = [];
      for(i = 0; i < this.state.statesList.length; i++){
        if(this.state.statesList[i].toLowerCase().includes(substr.toLowerCase())){
            tempArray.push(this.state.statesList[i]);
        }
      }
      tempArray = tempArray.sort((a, b) => a.localeCompare(b));
      newTemp = [];
      //newTemp = this.state.returnStates;
      for(i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        //console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });
     

      if(this.state.returnStates.length === 0){
        this.setState({ message: "There are no matches for " });
      }
      else{
        this.setState({ message: "Here is the alphabetically sorted list of all the " });
      }


    }
  }

  render() {
    return (
      <div>
        <div className="overall">
          <div className="header">Under Construction</div>
          <div className="text">
            This page is under construction! Stay tuned for updates.
          </div>
        </div>
      </div>
    );
  }
}

export default UnderConstruction;
