import React, {Component} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../Chat/Chat.css';
import images from '../Themes/Images.js'
import {AppString} from '../chatConstants.js'


export default class WelcomeBoard extends Component {
    render() {
        return (
            <div className="viewWelcomeBoard">
        <span className="textTitleWelcome">{`Welcome, ${
            localStorage.getItem(AppString.NAME)
            }`}</span>
                <img
                    className="avatarWelcome"
                    //src={this.props.currentUserAvatar}
                    src={images.ic_default_avatar}
                    alt="icon avatar"
                />
                <span className="textDesciptionWelcome">
          Click on a user on the left to start talking!
        </span>
            </div>
        )
    }
}