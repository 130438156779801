import React from 'react';
import './../UnderConstruction/UnderConstruction.css';



class UnderConstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //statesList: window.cs142models.statesModel(),
      returnStates: [],
      substring: '',
      inputValue: '',
      i: 100,
      r: 100,
      message: "Here is the alphabetically sorted list of all the ",
      buttonWasClicked: '',
    };

    //console.log('window.cs142models.statesModel()', window.cs142models.statesModel());
  }

  render() {
    return (
      <div>
        <div className="overall">
          <div className="header">Members-only content</div>
          <div className="text_terms">
            Oops! You need to be signed in to access this content.
          </div>
        </div>
      </div>
    );
  }
}

export default UnderConstruction;
