import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";

class PayPalBtn extends React.Component {
    render() {
      const { amount, onSuccess, currency, /**/onApprove, onError, catchError, onCancel, createSubscription} = this.props;
        return (
            <PayPalButton
              amount={amount}
              currency={currency}
              onSuccess={(details, data) => onSuccess(details, data)}
              createSubscription={(data, details) => createSubscription(data, details)}
            
              //added for subscriptions
              onApprove={(data, details) => onApprove(data, details)}
              onError={(err) => onError(err)}
              catchError={(err) => catchError(err)}
              onCancel={(err) => onCancel(err)}

              options={{
                //clientId: "AYFqbKyNhYYuHs7D8npByYLGvE4hXG1r1wWuXCxlAjqFfX_HPMJElK4wW1j52w-QXmRXJ8BZ7f8hONm1",
                clientId: "AcICtcusn4g6wQFqBPtjo1LGby15DBI8gG2T9hP7QGcDL2NBF-XA73Jo_e0gO12gmmrm3twhS0TxudOl",
                //added for subscriptions
                vault: true,
              }}

              experience={{
                    noShipping: 1,
              }}
          />
        );
    }
}
export default PayPalBtn;
