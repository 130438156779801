export const AppString = {
    ID: 'id',
    PHOTO_URL: 'photoUrl',
    NICKNAME: 'nickname',
    ABOUT_ME: 'aboutMe',
    NODE_MESSAGES: 'messages',
    NODE_USERS: 'users',
    UPLOAD_CHANGED: 'state_changed',
    DOC_ADDED: 'added',
    PREFIX_IMAGE: 'image/', 
    ACCOUNT_TYPE: 'account_type',
    EMAIL: 'email',
    SUBSCRIPTION_LENGTH: 'subscription_length',
    SUBSCRIPTION_END_DATE: 'subscription_end_date',
    SUBSCRIPTION_START_DATE: 'subscription_start_date'
}