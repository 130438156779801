import moment from 'moment'
import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import 'react-toastify/dist/ReactToastify.css'
import { withFirebase } from '../Firebase';
import images from '../Themes/Images.js'
import { AppString } from '../chatConstants.js'
import * as emailjs from 'emailjs-com'


class ChatBoard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            inputValue: '',
        }
        this.currentUserId = localStorage.getItem(AppString.ID)
        this.currentUserAvatar = localStorage.getItem(AppString.PHOTO_URL)
        this.currentUserName = localStorage.getItem(AppString.NAME)
        this.currentEmail = localStorage.getItem(AppString.EMAIL)
        this.peerEmail = this.props.peerEmail
        this.listMessage = []
        this.currentPeerUser = this.props.currentPeerUser
        this.firstNameOnly = this.props.firstNameOnly
        this.groupChatId = null
        this.removeListener = null
        this.currentPhotoFile = null
        this.sendMail = this.sendMail.bind(this)
    }

    componentDidUpdate() {
        this.scrollToBottom()
        //console.log("CURRENT PEER: " + this.currentPeerUser);
        //console.log("CURRENT USER NAME: " + this.currentUserName);
        //console.log("PEER EMAIL: " + this.peerEmail);
        this.props.firebase.setSeen(this.currentEmail, this.groupChatId);
    }

    componentDidMount() {
        //console.log("RECIEVED curr eer user: " + this.currentPeerUser);
        //console.log("RECIEVED OTHER EMAIL: " + this.peerEmail);
        let image_link = this.currentPeerUser.replace(" ", "_").toLowerCase();
        //console.log("image link: " + image_link)
        let image_att = images[`${image_link}`]
        //console.log("image attempt: " + image_att)
        this.setState({image_attempt: image_att})

        // For first render, it's not go through componentWillReceiveProps
        this.getListHistory()

        //removing this because component is not mounted if you send multiple messages to people and switch users to new person
        //this.props.firebase.createChat(this.currentEmail, this.peerEmail, this.groupChatId, this.currentUserName, this.currentPeerUser);
    }

    componentWillUnmount() {
        if (this.removeListener) {
            this.removeListener()
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.currentPeerUser) {
            this.currentPeerUser = newProps.currentPeerUser

            //remove last name
            this.firstNameOnly = newProps.firstNameOnly

            this.getListHistory()

            let image_link = this.currentPeerUser.replace(" ", "_").toLowerCase();
            //console.log("image link: " + image_link)
            let image_att = images[`${image_link}`]
            //console.log("image attempt: " + image_att)
            this.setState({image_attempt: image_att})
        }
        if(newProps.peerEmail){
            this.peerEmail = newProps.peerEmail
            //console.log("got peer email: " + this.peerEmail)
        }
    }

    getListHistory = () => {
        if (this.removeListener) {
            this.removeListener()
        }
        this.listMessage.length = 0
        this.setState({isLoading: true})
        if (
            this.hashString(this.currentUserId) <=
            this.hashString(this.currentPeerUser)
            //this.hashString(this.currentPeerUser.otherPerson)
        ) {
            this.groupChatId = `${this.currentUserId}-${this.currentPeerUser}`
            //this.groupChatId = `${this.currentUserId}-${this.currentPeerUser.otherPerson}`
        } else {
            this.groupChatId = `${this.currentPeerUser}-${this.currentUserId}`
            //this.groupChatId = `${this.currentPeerUser.otherPerson}-${this.currentUserId}`
        }
        console.log("groupchatid" + this.groupChatId);

        // Get history and listen new data added
        this.removeListener = this.props.firebase.myFirestore
            .collection(AppString.NODE_MESSAGES)
            .doc(this.groupChatId)
            .collection(this.groupChatId)
            .onSnapshot(
                snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === AppString.DOC_ADDED) {
                            this.listMessage.push(change.doc.data())
                        }
                    })
                    this.setState({isLoading: false})
                },
                err => {
                    this.props.showToast(0, err.toString())
                }
            )
    }

    onSendMessage = (content, type) => {
        if (content.trim() === '') {
            return
        }

        const timestamp = moment()
            .valueOf()
            .toString()

        const itemMessage = {
            idFrom: this.currentUserId,
            idTo: this.currentPeerUser,
            //idTo: this.currentPeerUser.otherPerson,
            timestamp: timestamp,
            content: content.trim(),
            type: type
        }

        //create chat in both users 
        this.props.firebase.createChat(this.currentEmail, this.peerEmail, this.groupChatId, this.currentUserName, this.currentPeerUser);

        this.props.firebase.myFirestore
            .collection(AppString.NODE_MESSAGES)
            .doc(this.groupChatId)
            .collection(this.groupChatId)
            .doc(timestamp)
            .set(itemMessage)
            .then(() => {
                this.setState({ inputValue: '' })

                this.sendMail();
            })
            .catch(err => {
                this.props.showToast(0, err.toString())
            })

        //FIX THIS
        //this.props.firebase.setUnseen(this.currentPeerUser.otherPersonEmail, this.groupChatId, this.currentPeerUser.otherPerson);
    }

    sendMail() {
        window.Email.send({
            //noreply.collegiatetutornetwork@gmail.com (using smtpjs && gmail server)
            SecureToken: "136ec8f5-d865-462f-861e-dd5e727680da",
            From: "noreply.collegiatetutornetwork@gmail.com",

            //neha gmail
            //SecureToken: "130346f0-604f-46bc-b035-0f0c82089bba",
            //From: "nehasharma0499@gmail.com",

            To: this.peerEmail,
            Subject: "Collegiate Tutor Network: New Message",
            Body: "Hello, <br /> <br />  You have received a new message from " + this.currentUserId + ". To view the message, please visit <a href='https://collegiatetutornetwork.com'>collegiatetutornetwork.com</a>. <br /> <br />  Thank you, <br />  Collegiate Tutor Network Team"
        }).then(
            //message => alert(message)
        );
        console.log("Sent Email Notification")
    }

    onChoosePhoto = event => {
        if (event.target.files && event.target.files[0]) {
            this.setState({isLoading: true})
            this.currentPhotoFile = event.target.files[0]
            // Check this file is an image?
            const prefixFiletype = event.target.files[0].type.toString()
            if (prefixFiletype.indexOf(AppString.PREFIX_IMAGE) === 0) {
                this.uploadPhoto()
            } else {
                this.setState({isLoading: false})
                this.props.showToast(0, 'This file is not an image')
            }
        } else {
            this.setState({isLoading: false})
        }
    }

    uploadPhoto = () => {
        if (this.currentPhotoFile) {
            const timestamp = moment()
                .valueOf()
                .toString()

            const uploadTask = this.props.firebase.myStorage
                .ref()
                .child(timestamp)
                .put(this.currentPhotoFile)

            uploadTask.on(
                AppString.UPLOAD_CHANGED,
                null,
                err => {
                    this.setState({isLoading: false})
                    this.props.showToast(0, err.message)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.setState({isLoading: false})
                        this.onSendMessage(downloadURL, 1)
                    })
                }
            )
        } else {
            this.setState({isLoading: false})
            this.props.showToast(0, 'File is null')
        }
    }

    onKeyboardPress = event => {
        if (event.key === 'Enter') {
            this.onSendMessage(this.state.inputValue, 0)
        }
    }

    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({})
        }
    }

    render() {
        return (
            <div className="viewChatBoard">
                {/* Header */}
                <div className="headerChatBoard">
                    <img
                        className="viewAvatarItem"
                        //src={this.currentPeerUser.photoUrl}
                        //src={images.ic_default_avatar}
                        src={this.state.image_attempt != null ? this.state.image_attempt : images.ic_default_avatar}
                        alt="icon avatar"
                    />
                    <span className="textHeaderChatBoard">
                        {this.firstNameOnly}
                        {/*{this.currentPeerUser.otherPerson}*/}
                    </span>
                </div>

                {/* List message */}
                <div className="viewListContentChat">
                    {this.renderListMessage()}
                    <div
                        style={{float: 'left', clear: 'both'}}
                        ref={el => {
                            this.messagesEnd = el
                        }}
                    />
                </div>
                
                {/* View bottom */}
                <div className="viewBottom">
                    <img
                        className="icOpenGallery"
                        src={images.ic_photo}
                        alt="icon open gallery"
                        onClick={() => this.refInput.click()}
                    />
                    <input
                        ref={el => {
                            this.refInput = el
                        }}
                        accept="image/*"
                        className="viewInputGallery"
                        type="file"
                        onChange={this.onChoosePhoto}
                    />

                    <input
                        className="viewInput"
                        placeholder="Type your message..."
                        value={this.state.inputValue}
                        onChange={event => {
                            this.setState({inputValue: event.target.value})
                        }}
                        onKeyPress={this.onKeyboardPress}
                    />
                    <img
                        className="icSend"
                        src={images.ic_send}
                        alt="icon send"
                        onClick={() => this.onSendMessage(this.state.inputValue, 0)}
                    />
                </div>

                {/* Loading */}
                {this.state.isLoading ? (
                    <div className="viewLoading">
                        <ReactLoading
                            type={'spin'}
                            color={'#203152'}
                            height={'3%'}
                            width={'3%'}
                        />
                    </div>
                ) : null}
            </div>
        )
    }

    renderListMessage = () => {
        if (this.listMessage.length > 0) {
            let viewListMessage = []
            this.listMessage.forEach((item, index) => {
                if (item.idFrom === this.currentUserId) {
                    // Item right (my message)
                    if (item.type === 0) {
                        viewListMessage.push(
                            <div className="viewItemRight" key={item.timestamp}>
                                <span className="textContentItem">{item.content}</span>
                            </div>
                        )
                    } else if (item.type === 1) {
                        viewListMessage.push(
                            <div className="viewItemRight2" key={item.timestamp}>
                                <img
                                    className="imgItemRight"
                                    src={item.content}
                                    alt="content message"
                                />
                            </div>
                        )
                    } else {
                        viewListMessage.push(
                            <div className="viewItemRight3" key={item.timestamp}>
                                <img
                                    className="imgItemRight"
                                    src={this.getGifImage(item.content)}
                                    alt="content message"
                                />
                            </div>
                        )
                    }
                } else {
                    // Item left (peer message)
                    if (item.type === 0) {
                        viewListMessage.push(
                            <div className="viewWrapItemLeft" key={item.timestamp}>
                                <div className="viewWrapItemLeft3">
                                    {this.isLastMessageLeft(index) ? (
                                        <img
                                            //src={this.currentPeerUser.photoUrl}
                                            src={images.ic_default_avatar}
                                            alt="avatar"
                                            className="peerAvatarLeft"
                                        />
                                    ) : (
                                        <div className="viewPaddingLeft"/>
                                    )}
                                    <div className="viewItemLeft">
                                        <span className="textContentItem">{item.content}</span>
                                    </div>
                                </div>
                                {this.isLastMessageLeft(index) ? (
                                    <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                                ) : null}
                            </div>
                        )
                    } else if (item.type === 1) {
                        viewListMessage.push(
                            <div className="viewWrapItemLeft2" key={item.timestamp}>
                                <div className="viewWrapItemLeft3">
                                    {this.isLastMessageLeft(index) ? (
                                        <img
                                            //src={this.currentPeerUser.photoUrl}
                                            src={images.ic_default_avatar}
                                            alt="avatar"
                                            className="peerAvatarLeft"
                                        />
                                    ) : (
                                        <div className="viewPaddingLeft"/>
                                    )}
                                    <div className="viewItemLeft2">
                                        <img
                                            className="imgItemLeft"
                                            src={item.content}
                                            alt="content message"
                                        />
                                    </div>
                                </div> 
                                {this.isLastMessageLeft(index) ? (
                                    <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                                ) : null}
                            </div>
                        )
                    } else {
                        viewListMessage.push(
                            <div className="viewWrapItemLeft2" key={item.timestamp}>
                                <div className="viewWrapItemLeft3">
                                    {this.isLastMessageLeft(index) ? (
                                        <img
                                           // src={this.currentPeerUser.photoUrl}
                                            src={images.ic_default_avatar}
                                            alt="avatar"
                                            className="peerAvatarLeft"
                                        />
                                    ) : (
                                        <div className="viewPaddingLeft"/>
                                    )}
                                    <div className="viewItemLeft3" key={item.timestamp}>
                                        <img
                                            className="imgItemLeft"
                                            src={this.getGifImage(item.content)}
                                            alt="content message"
                                        />
                                    </div>
                                </div>
                                {this.isLastMessageLeft(index) ? (
                                    <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                                ) : null}
                            </div>
                        )
                    }
                }
            })
            return viewListMessage
        } else {
            return (
                <div className="viewWrapSayHi">
                    <span className="textSayHi">Say hi</span>
                    <img
                        className="imgWaveHand"
                        src={images.ic_wave_hand}
                        alt="wave hand"
                    />
                </div>
            )
        }
    }


    hashString = str => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
            hash = hash & hash // Convert to 32bit integer
        }
        return hash
    }

    getGifImage = value => {
        switch (value) {
            case 'mimi1':
 //               return images.mimi1
            case 'mimi2':
 //               return images.mimi2
            case 'mimi3':
  //              return images.mimi3
            case 'mimi4':
 //               return images.mimi4
            case 'mimi5':
  //              return images.mimi5
            case 'mimi6':
  //              return images.mimi6
            case 'mimi7':
  //              return images.mimi7
            case 'mimi8':
 //               return images.mimi8
            case 'mimi9':
 //               return images.mimi9
            default:
                return null
        }
    }

    isLastMessageLeft(index) {
        if (
            (index + 1 < this.listMessage.length &&
                this.listMessage[index + 1].idFrom === this.currentUserId) ||
            index === this.listMessage.length - 1
        ) {
            return true
        } else {
            return false
        }
    }

    isLastMessageRight(index) {
        if (
            (index + 1 < this.listMessage.length &&
                this.listMessage[index + 1].idFrom !== this.currentUserId) ||
            index === this.listMessage.length - 1
        ) {
            return true
        } else {
            return false
        }
    }
}


export default withFirebase(ChatBoard)