import React, {Component} from 'react'
import ReactLoading from 'react-loading'
import {withRouter} from 'react-router-dom'
import images from '../Themes/Images.js'
import WelcomeBoard from '../WelcomeBoard'
import ChatBoard from '../ChatBoard'
import {AppString} from '../chatConstants'
import './Chat.css';
import { withFirebase } from '../Firebase';

import { compose } from 'recompose';


import {
    AuthUserContext,
    withAuthorization,
    withEmailVerification,
  } from '../Session';

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isOpenDialogConfirmLogout: false,
            currentPeerUser: null,
            peerEmail: null,
            peer: null,
            firstNameOnly: null,
        }
        this.currentUserId = localStorage.getItem(AppString.ID)
        this.currentUserAvatar = localStorage.getItem(AppString.PHOTO_URL)
        this.currentUserNickname = localStorage.getItem(AppString.NICKNAME)
        this.accountType = localStorage.getItem(AppString.ACCOUNT_TYPE)
        this.currentEmail = localStorage.getItem(AppString.EMAIL)
        this.listUser = []
        this.otherPerson = ""
        this.result = ""
    }

    componentDidMount() {
        this.checkLogin()

        this.setState({currItems: this.state.items});  
        let result = [];
        if(this.props.location.state != undefined && this.props.location.state.chatOpened){
            /*this.props.firebase.myFirestore.collection("users").doc(this.currentEmail).collection("chats").doc(this.props.location.state.groupChatId).get().then(doc => {
                //this.setState({currentPeerUser : doc.data()})
                this.setState({currentPeerUser : doc.data().id})
                console.log("from tutor age: " + doc.data().id)
            })*/
            this.setState({peer: this.state.peer});
        }
    }

    checkLogin = () => {
        if (!localStorage.getItem(AppString.ID)) {
            //console.log("yehaw")
            this.setState({isLoading: false}, () => {
                this.props.history.push('/')
            })
        } else {
            //console.log("yoohoo")
            this.getListUser()
        }
    }

    getListUser = async () => {
        if(this.accountType == "student"){
            const result = await this.props.firebase.myFirestore.collection("tutors").get()

            if (result.docs.length > 0) {
                this.listUser = [...result.docs]
                this.setState({isLoading: false})
                //console.log("got other users list")
                //console.log("list user: " + this.listUser)
            }
        }
        else{
            const result = await this.props.firebase.myFirestore.collection("users").doc(this.currentEmail).collection("chats").get()

        if (result.docs.length > 0) {
            this.listUser = [...result.docs]
            this.setState({isLoading: false})
            //console.log("got other users list")
            //console.log("list user: " + this.listUser)
        }
        }
    }  

    /*getListUser = async () => {
        const result = await this.props.firebase.myFirestore.collection("users").doc(this.currentEmail).collection("chats").get()

        if (result.docs.length > 0) {
            this.listUser = [...result.docs]
            this.setState({isLoading: false})
            console.log("got other users list")
            console.log("list user: " + this.listUser)
        }
    }*/

    renderListUser = () => {
        if(this.accountType == "student"){
            if (this.listUser.length > 0) {
                let viewListUser = []
                this.listUser.forEach((item, index) => {
                    let image_link = item.data().id.replaceAll(" ", "_").toLowerCase();
                    //console.log("image link: " + image_link)
                    if(item.data().id == "Mauricio Tellez-Sanchez"){
                        image_link = "mauricio_tellez_sanchez"
                    }
                    else{
                        //image_attempt = images[`${image_link}`]
                    }
                    /*if(item.data().id === "Shiriel King Abramson"){
                        console.log("shiriel");
                        image_attempt = images.shiriel_abramson;
                    }*/

                    //remove last name
                    let first_name_only = item.data().id.substr(0, item.data().id.indexOf(' '));

                    this.otherPerson = item.data().otherPerson;
                    if(item.data().unseen == true){
                        //console.log("UNSEEN")
                    }
                    else{
                        //console.log("SEEN")
                    }

                    //do not show test accts
                    if (item.id != "collegiatetutornetwork@gmail.com") {
                        viewListUser.push(
                            <button
                                key={index}
                                className={
                                    this.state.currentPeerUser &&
                                        this.state.currentPeerUser === item.data().id
                                        //this.state.currentPeerUser === item.data().otherPerson
                                        ? 'viewWrapItemFocused'
                                        : 'viewWrapItem'
                                }
                                onClick={() => {
                                    this.setState({ currentPeerUser: item.data().id })
                                    this.setState({ peerEmail: item.id })
                                    //console.log("TESTING CURRENT: " + item.data().id)
                                    //this.setState({currentPeerUser: item.data()})
                                    this.setState({ firstNameOnly: first_name_only })
                                }}
                            >
                                <img
                                    className="viewAvatarItem"
                                    //src={item.data().photoUrl}
                                    //src={images.ic_default_avatar}
                                    src={images[image_link] != null ? images[image_link] : images.ic_default_avatar}
                                    alc={images.ic_default_avatar}
                                />
                                <div className="viewWrapContentItem">
                                    <span className="textItem">{`${first_name_only
                                        //item.data().id
                                        /*this.otherPerson*/
                                        }`}
                                    </span>
                                </div>
                                <div className="notif">
                                    {item.data().unseen && <img
                                        //src={item.data().photoUrl}
                                        src={images.notification}
                                        alt="icon avatar"
                                    />}
                                </div>
                            </button>
                        )
                    }
                })
                return viewListUser
            } else {
                return null
            }
        }
        else{
            //console.log("in render list user function");
            if (this.listUser.length > 0) {
                //console.log("list user is > 0");
                let viewListUser = []
                this.listUser.forEach((item, index) => {
                    //console.log("ITEM DATA: " + item.data());

                    //new stuff
                    //console.log("TRY: " + item.data().id)

                   

                    //console.log("currentuserid: " + this.currentUserId);
                    this.otherPerson = item.data().otherPerson;
                    //console.log("OTHER PERSON: " + item.data().otherPerson)
                    //console.log("TRYING TO GET EMAIL: " + item.id);
                    if(item.data().unseen == true){
                        //console.log("UNSEEN")
                    }
                    else{
                        //console.log("SEEN")
                    }

                    //last name only
                    let first_name_only = this.otherPerson.substr(0, this.otherPerson.indexOf(' '));

                    viewListUser.push(
                        <button
                            key={index}
                            className={
                                this.state.currentPeerUser &&
                                //this.state.currentPeerUser === item.data().id
                                this.state.currentPeerUser === item.data().otherPerson
                                    ? 'viewWrapItemFocused'
                                    : 'viewWrapItem'
                            }
                            onClick={() => {
                                //this.setState({currentPeerUser: item.data().id})
                                this.setState({peerEmail: item.data().otherPersonEmail})
                                //console.log("TESTING CURRENT: " + item.data().id)
                                this.setState({ currentPeerUser: item.data().otherPerson })
                                this.setState({ firstNameOnly: first_name_only })
                            }}
                        >
                            <img
                                className="viewAvatarItem"
                                //src={item.data().photoUrl}
                                src={images.ic_default_avatar}
                                alt="icon avatar"
                            />
                            <div className="viewWrapContentItem">
                                <span className="textItem">{`${
                                first_name_only
                                //this.otherPerson
                                }`}
                            </span>
                            </div>
                            <div className="notif">
                            {item.data().unseen && <img
                                //src={item.data().photoUrl}
                                src={images.notification}
                                alt="icon avatar"
                            />}
                            </div>
                        </button>
                    )
                })
                return viewListUser
            } else {
                return null
            }
        }
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => (

                    
            <div className="root">
                {/* Body */}
                <div className="body">
                    <div className="viewListUser"> {this.renderListUser()}</div>
                    <div className="viewBoard">
                        {this.state.currentPeerUser ? (
                            <ChatBoard
                                currentPeerUser={this.state.currentPeerUser}
                                showToast={this.props.showToast}
                                peerEmail={this.state.peerEmail}
                                firstNameOnly={this.state.firstNameOnly}
                            />
                        ) : (
                            <WelcomeBoard
                                currentUserNickname={this.currentUserNickname}
                                currentUserAvatar={this.currentUserAvatar}
                            />
                        )}
                    </div>
                </div>

                {/* Dialog confirm */}
                {this.state.isOpenDialogConfirmLogout ? (
                    <div className="viewCoverScreen">
                        {this.renderDialogConfirmLogout()}
                    </div>
                ) : null}

                {/* Loading */}
            </div>
        )}
  </AuthUserContext.Consumer>
        )}
}

//export default withFirebase(Chat)
const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(Chat);