import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {AppString} from '../chatConstants'

import './../SignIn/SignIn.css';

const PasswordForgetPage = () => (
  <div className="container_signin">
    <h1>Password Reset</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  passForget: false,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.currentEmail = localStorage.getItem(AppString.EMAIL)

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = () => {
    this.setState({passForget: true})
    const { email } = this.state;
    console.log(this.currentEmail);

    this.props.firebase
      .doPasswordReset(this.currentEmail)
      .then(() => {
        console.log("RESET EMAIL");
        this.setState({email: ''})
        this.setState({error: null})
        //this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, passForget} = this.state;

    const isInvalid = email === '';

    return (
      <div>
        <button className="button_reset" onClick={this.onSubmit}>
          Reset My Password
        </button>
        {this.state.passForget && <p id="red">You should have received an email with a link to reset your password.</p>}

        <div className="error"> {error && <p>{error.message}</p>} </div>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link className="link_signin" to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
