import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import './BottomNavigation.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebookF); 



const BottomNavigation = () => (
    <div className="masterbottom">
        <FontAwesomeIcon icon={['fab', 'facebook-f']} />

      <a className="link" href={`mailto:collegiatetutornetwork@gmail.com`}>Contact Us</a>
      {/*<Link class="link" to={ROUTES.CONTACT}>Contact Us</Link>*/}
      <Link className="link" to={ROUTES.TERMS}>Terms and Conditions</Link>    
      <Link className="link" to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
      {/*<Link className="link" to={ROUTES.SUBSCRIPTION_POLICY}>Subscription Policy</Link>*/}
  </div>
);

export default BottomNavigation;
