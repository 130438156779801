export const LANDING = '/';
export const TUTOR_SIGN_UP = '/tutor_signup';
export const STUDENT_SIGN_UP = '/student_signup';
export const SIGN_UP_INITIAL = '/choose_account_type';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const HOW_IT_WORKS = '/how_it_works';
export const TUTORS = '/tutors';
export const SUBSCRIPTION_POLICY = '/subscription_policy';
export const CONTACT = '/contact';
export const PRIVACY_POLICY = '/privacy_policy';
export const TERMS = '/terms';
export const UNDER_CONSTRUCTION = '/404';
export const CHAT = '/chat';
export const BLOG = '/blog';
export const WELCOMEBOARD = '/welcomeboard';
export const REQUIRESSIGNIN = '/require_signin';





