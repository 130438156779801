import React from 'react';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
import { HashRouter, Route, Link, Router } from 'react-router-dom';

import Navigation from '../Navigation';
import BottomNavigation from '../BottomNavigation';
import LandingPage from '../Landing';
import TutorSignUp from '../TutorSignUp';
import StudentSignUp from '../StudentSignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import HowItWorks from '../HowItWorks';
import SubscriptionPolicy from '../SubscriptionPolicy';
import UnderConstruction from '../UnderConstruction';
import Terms from '../Terms';
import Tutors from '../Tutors';
import Chat from '../Chat';
import Blog from '../Blog';
import SignUpInitial from '../SignUpInitial';
import RequiresSignIn from '../RequiresSignIn';
import PrivacyPolicy from '../PrivacyPolicy';
import '../App/App.css';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import WelcomeBoard from '../WelcomeBoard';

import ReactGA from 'react-ga';
const trackingId = "UA-174468587-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => (
  /*<Router className="overall" basename={window.location.pathname || ''}>*/
  <HashRouter className="overall">
    <div className="overall"> 
      <Navigation />

      <div className="app">
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.TUTOR_SIGN_UP} component={TutorSignUp} />
        <Route path={ROUTES.STUDENT_SIGN_UP} component={StudentSignUp} />
        <Route path={ROUTES.SIGN_UP_INITIAL} component={SignUpInitial} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route path={ROUTES.HOW_IT_WORKS} component={HowItWorks} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.TUTORS} component={Tutors} />
        <Route path={ROUTES.SUBSCRIPTION_POLICY} component={SubscriptionPolicy} />
        <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path={ROUTES.CONTACT} component={UnderConstruction} />
        <Route path={ROUTES.TERMS} component={Terms} />
        <Route path={ROUTES.UNDER_CONSTRUCTION} component={UnderConstruction} />
        <Route path={ROUTES.CHAT} component={Chat} />
        <Route path={ROUTES.BLOG} component={Blog} />
        <Route path={ROUTES.WELCOMEBOARD} component={WelcomeBoard} />
        <Route path={ROUTES.REQUIRESSIGNIN} component={RequiresSignIn} />
      </div>

      <BottomNavigation className="test"/>
    </div>
  {/*</Router>*/}
  </HashRouter>
);

export default withAuthentication(App);
