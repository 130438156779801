import React from 'react';
import './HowItWorks.css';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';



class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //statesList: window.cs142models.statesModel(),
      returnStates: [],
      substring: '',
      inputValue: '',
      i: 100,
      r: 100,
      message: "Here is the alphabetically sorted list of all the ",
      buttonWasClicked: '',
    };
    this.init();
    this.handleChangeBoundNew = event => this.handleChangeMotto(event);
    this.handleChangeMotto = this.handleChangeMotto.bind(this);

    //console.log('window.cs142models.statesModel()', window.cs142models.statesModel());
  }

  init(){
    var tempArray = [];
    //tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
    var newTemp = this.state.returnStates;
    for(var i = 0; i < tempArray.length; i++){
      newTemp[i] = <li key={i}> {tempArray[i]} </li>;
      console.log('newTemp[i]', newTemp[i]);
      //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      //this.setState({returnStates: [...newTemp, <li key={i}> {tempArray[i]} </li>]});
      //this.setState({returnStates: this.state.returnStates.concat([<li key={i}> {tempArray[i]} </li>])})
    }
    this.setState({ returnStates: newTemp });
     console.log('newTemp', newTemp);
      console.log('userNamesList', this.state.returnStates);
  }

  handleChangeMotto(event){
    var substr = event.target.value;
    this.setState({ substring: event.target.value, i: this.state.statesList.length, r:this.state.returnStates.length });

    if(substr === ""){
      this.setState({ message: "Here is the alphabetically sorted list of all the" });
      //this.state.message = "Here is the alphabetically sorted list of all the ";
      var tempArray = [];
      var newTemp = this.state.returnStates;
      tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
      for(var i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });

    }
    else{
      //var emptyArr = [];
      //this.setState({ returnStates: emptyArr });
      //this.state.returnStates = [];
      tempArray = [];
      for(i = 0; i < this.state.statesList.length; i++){
        if(this.state.statesList[i].toLowerCase().includes(substr.toLowerCase())){
            tempArray.push(this.state.statesList[i]);
        }
      }
      tempArray = tempArray.sort((a, b) => a.localeCompare(b));
      newTemp = [];
      //newTemp = this.state.returnStates;
      for(i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        //console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });
     

      if(this.state.returnStates.length === 0){
        this.setState({ message: "There are no matches for " });
      }
      else{
        this.setState({ message: "Here is the alphabetically sorted list of all the " });
      }


    }
  }

  render() {
    return (
      <div>
        <div class="overall">
          <div class="header">Step 1: Browse our tutors</div>
          <div class="text_terms">Go to our <Link className="link_how_it_works" to={ROUTES.TUTORS}>tutors page</Link> to browse our roster of tutors. If you can’t find a tutor in the subject you need, <a className="link_how_it_works" href={`mailto:collegiatetutornetwork@gmail.com`}>contact us</a> and we’ll find you a tutor from a top university who specializes in that subject. All of our tutors have passed rigorous background checks.
          </div>
          
          <div class="header"><strong>Step 2: Make an account and choose a subscription</strong></div>
          <div class="text_terms">To be able to message tutors, you must first     <Link className="link_how_it_works" to={ROUTES.SIGN_IN}>become a member</Link>. Membership requires a subscription - you can choose between a $50 quarterly fee or a $80 bi-annual fee. You can cancel your membership at any time. Payment is conducted securely using PayPal.
          </div>

          <div class="header"><strong>Step 3: Message tutors</strong></div>
          <div class="text_terms">Message a few tutors you are interested in. We recommend setting up an introductory virtual meeting, so you can speak face to face and get to know the tutor before deciding. Discuss your student’s needs, compensation, scheduling, etc. Payment for lessons is given directly from you to the tutor.
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
