import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import {AppString} from '../chatConstants.js'
import Modal from 'react-modal';
import Terms from '../Terms';
import images from '../Themes/Images.js'

import './../SignIn/SignIn.css';

const SignUpPage = () => (
  <div className="container_signin">
    <h1>Sign Up</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
  
  accessCode: '',
  accessMaster: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.state={
      modalOpened: false,
      accepted: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.submitAccessCode = this.submitAccessCode.bind(this);
  }

  componentDidMount() {
    this.state.modalOpened = false;
    this.state.accepted = false;
  }

  componentDidUpdate() {
      // I'd like my variable to be accessible here
      console.log("updated");
  }

  onSubmit = event => {
    event.preventDefault();
    const { username, email, passwordOne, accepted } = this.state;
    const roles = {};

   /* if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }  */

      this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(authUser => {
          // Create a user in your Firebase realtime database
          return this.props.firebase.user(authUser.user.uid).set({
            username,
            email,
            roles,
          });
        })
        .then(() => {
          return this.props.firebase.doSendEmailVerification();
        })
        .then(() => {
          
          //added by me
          this.props.firebase.addNewTutorAccount(email, username);
          ///////////        

          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.LANDING);
        })
        .catch(error => {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          this.setState({ error });
        });

      event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onAcceptTerms = event => {
    //this.state.accepted = !this.state.accepted;
    this.setState(prevState => ({accepted: !prevState.accepted}));
    console.log("accepted: " + this.state.accepted);
    console.log("modal: " + this.state.modalOpened);
  };

  toggleModal() {
    this.setState(prevState => ({modalOpened: !prevState.modalOpened}));
    console.log("accepted: " + this.state.accepted);
    console.log("modal: " + this.state.modalOpened);
  };

  submitAccessCode() {
    console.log(this.state.accessCode);
    if(this.state.accessCode == 'j28dh2ksd8'){
        this.setState({accessMaster: true});
    }
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
      accepted,
      modalOpened,

      accessCode,
      accessMaster,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      !accepted ||
      username === '';

    return (
      <div>
        {accessMaster ? 
        <div>
            <Modal className="modal_tutor" onClick={this.toggleModal}
              isOpen={this.state.modalOpened}
              onRequestClose={this.toggleModal}>
              <Terms/>
            </Modal>

          <form className="form" onSubmit={this.onSubmit}>
            <input className="input"
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="Full Name"
            />
            <input className="input"
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <input className="input"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <input className="input"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
           <label className="labellll">
                <input
                  name="accepted"
                  type="checkbox"
                  checked={accepted}
                  onChange={this.onAcceptTerms}
                />
                I agree to the Collegiate Tutor Network <button className="none" onClick={this.toggleModal}>Terms of Service</button>.
            </label>
            <button className="button_signin" disabled={isInvalid} type="submit">
              Sign Up
            </button>

            <div className="error"> {error && <p>{error.message}</p>} </div>
          </form>
          </div>
           :
           <div>
              <p> Enter access code (from onboarding email): </p>
              <input className="input"
                  name="accessCode"
                  value={accessCode}
                  onChange={this.onChange}
                  type="password"
                  placeholder=""
                />
                <button className="button_signin" id="submit_button_space" onClick={this.submitAccessCode} type="submit">Submit</button>
                    <p> If you do not have an access code and would like to sign up to be a tutor, please reach out to <a href="mailto:collegiatetutornetwork@gmail.com" className="link_signin">collegiatetutornetwork@gmail.com</a> </p>  
              </div>
          }
      </div>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link className="link_signin" to={ROUTES.SIGN_UP_INITIAL}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
