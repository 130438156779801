import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { withFirebase } from '../Firebase';
import { PasswordForgetForm } from '../PasswordForget';
import './../SignIn/SignIn.css';
import {AppString} from '../chatConstants'



class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.subscriptionLength = localStorage.getItem(AppString.SUBSCRIPTION_LENGTH)
    this.subscriptionEndDate = localStorage.getItem(AppString.SUBSCRIPTION_END_DATE)
    this.accountType = localStorage.getItem(AppString.ACCOUNT_TYPE)
    this.name = localStorage.getItem(AppString.NAME)
  }
  componentDidMount() {
    this.subscriptionLength = localStorage.getItem(AppString.SUBSCRIPTION_LENGTH)
    if(localStorage.getItem(AppString.SUBSCRIPTION_LENGTH) == "3 month"){
      this.setState({subscriptionLength: "Quarterly"});
      this.setState({renewPeriod: 3})
    }
    else{
      this.setState({subscriptionLength: "Bi-Annual"})
      this.setState({renewPeriod: 6})
    }
    this.subscriptionStartDate = localStorage.getItem(AppString.SUBSCRIPTION_START_DATE)
    //console.log(localStorage.getItem(AppString.SUBSCRIPTION_START_DATE))
    this.accountType = localStorage.getItem(AppString.ACCOUNT_TYPE)
    this.name = localStorage.getItem(AppString.NAME)
    //console.log("START DATE" + this.subscriptionStartDate);
    if(this.subscriptionStartDate == null){
      //lol do something
    }
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container_signin">
            <h2>Account:</h2>
            <div><strong>Name: </strong> {this.name}</div>
            <br></br>
            <div><strong>Email: </strong> {authUser.email}</div>
            {(this.accountType == "student" && (
              <div>
                <h2>Subscription Details: </h2>
                <div><strong>Subscription Length: </strong>{this.subscriptionLength}
                <br></br>
                <br></br>
                <strong>Subscription Start Date:</strong> {this.subscriptionStartDate}
                <br></br>
                <br></br>
                <div>Your subscription will renew every {this.state.renewPeriod} months from this date.</div>
                <div>To cancel your subscription, you can either: 
                  <br></br>
                  <br></br>
                  Log into your <a className="link_how_it_works" target="_blank" href={`https://www.paypal.com/us/home`}>Paypal account</a> {'>'} Click "Profile" (the gear icon) on the top right corner of the page {'>'} Click "Payments" {'>'} Click "Manage automatic payments" {'>'} Select "Collegiate Tutor Network" {'>'} Click "Cancel" 
                  <br></br>
                  <br></br>
                  Or <a className="link_how_it_works" href={`mailto:collegiatetutornetwork@gmail.com`}>Contact Us</a>
                  <br></br>
                  <br></br>
                  If you cancel your subscription, you will lose the ability to message tutors and access blog posts once your current subscription period runs out.
                  </div>
                </div>
              </div>))
            }
            <h2>Password/Account Management: </h2>
            <PasswordForgetForm />
            <LoginManagement authUser={authUser} />
            <DeleteButton />
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);
    this.currentEmail = localStorage.getItem(AppString.EMAIL)
    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
      </div>
    );
  }
}


const DeleteButton = () => (
  <a className="button_delete" href={`mailto:collegiatetutornetwork@gmail.com?subject=Delete CTN Account Request`}>Delete Account</a>

);

const PassWordForget = () => (
  <div>
    <button className="button_delete" onClick={PassWordForget.onSubmit}>
      Reset My Password
    </button>
  </div>
);

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPage);
