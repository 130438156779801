import React from 'react';
import './Landing.css';
import { Link } from 'react-router-dom';
import images from '../Themes/Images.js'
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { AppString } from '../chatConstants.js'
import * as ROUTES from '../../constants/routes';

import ReactGA from 'react-ga';
const trackingId = "UA-174468587-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);



class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        //{id:1,name:'Zoë', email: "zoeaiko@gmail.com",img: images.zoe_sonnenberg, school: "STANFORD UNIVERSITY", year: "'18", major: "English, Theater and Performance Studies", languages: "English", subjects: "English/language arts, history, social studies, creative writing, theater/drama", location: "Remote", price: "$70-100", long_description: "Zoë is a writer and dramaturg from Chicago, Illinois. She graduated Phi Beta Kappa from Stanford University with a Bachelor's degree in English, with distinction, and a minor in Theater and Performance Studies. She is passionate about literature, new plays and musicals, education, and music, and works to ensure that her students have the tools to remain lifelong learners. She has tutored middle and high school students with several agencies in New York City, specializing in the humanities and college application/essay counseling. Zoë is currently pursuing her masters in Playwriting and Dramaturgy at the University of Glasgow (she continues to work remotely with students back in the US)."},
        {id:1,name:'Omar', email: "ramos16@stanford.edu",img: images.omar_enrique_ramos_escoto, school: "STANFORD UNIVERSITY", year: "'22", major: "Mechanical Engineering, Computer Science", languages: "English, Spanish", subjects: "Calculus 1-3, Pre-Calculus, SAT Math, Physics, Spanish", location: "Remote", price: "$45-60", long_description: "Hello! I'm Omar Ramos Escoto, and I'm currently a junior at Stanford University studying mechanical engineering. As someone with a deep appreciation for meaningful learning, I devoted most of my high school career to tutoring students from multiple age groups and backgrounds on essay writing as well as led SAT math classes for dozens of students who consistently saw significant increases in their scores. Having 500+ hours of tutoring experience (and being an older brother!) has taught me to  instruct with a patient and goal-driven approach that emphasizes true understanding and internalization of concepts through ways that are unique to each person. If you are looking for help with anything from Algebra to Calculus, I am definitely your person! I am further deeply interested and involved with physics, mechanics, and programming, earning top grades at my university in these subjects. If you are also looking for help in practicing your conversational Spanish skills with a native speaker or want help in many subjects I loved in high school from AP Psychology to AP Physics, I would love to help you grasp all the knowledge you will need to excel. Feel free to contact me with any questions you might have. Looking forward to being a part of your learning experience!"}, 
        {id:2,name:'Skya', email: "skyatheobald@gmail.com",img: images.skya_theobald, school: "STANFORD UNIVERSITY", year: "'24", major: "English", subjects: "College Application Counseling, Math (Pre-Algebra, Algebra, Geometry, Trigonometry, Algebra 2, Pre-Calculus), English, Creative Writing, SAT Test Prep", languages: "English", location: "Remote, New York", price: "$40-60", long_description: "I started tutoring algebra and geometry in 8th grade. Since then, I've tutored English, history, biology, chemistry, and different levels of math up to precalculus. The simplicity of math is beautiful. It's not disputable, yet it's also creative: concepts are interconnected and can be broken down in different ways. I love deriving formulas and breaking concepts down to the simple truths for students I tutor. I want to share that excitement about math, make it fun, and find a way of explaining concepts that will make sense to my students. In the past I've helped students who were worried about passing and had one student go from 60s to 90s after a few months of tutoring. I'm an award-winning writer and would love to tutor students in English and workshop their writing. I'm especially interested if you are a creative writer, as I'm planning on pursuing a career in stage and screen writing. If you are learning from home this year, I can also teach subjects from scratch. I was homeschooled from 1st-6th grade and will be assisting in homeschooling my little brothers this year. If you’re going into your junior or senior year and aiming to get into a top college, I can help! I was accepted into a number of top universities, including Stanford University, Brown University, UC Berkley and NYU Tisch. We’ll brainstorm and workshop your essays, develop projects and build your resume! I've written a research paper on education reform and am always interested in new ways of learning. Hopefully, together we can change the way you see your education."},
        {id:3,name:'Jessica', email: "jbseng@alumni.stanford.edu",img: images.jessica_seng, school: "STANFORD UNIVERSITY", year: "'20", major: "Science, Technology and Society", languages: "English", subjects: "college app counseling, navigating college", location: "Remote", price: "$45-65", long_description: "Jessica Seng is a Cambodian-American writer from Tucson, Arizona. She graduated Stanford University (in the top 10% of her class) with honors in Science, Technology, and Society, an interdisciplinary honors in the arts, and a minor in creative writing. Part of the reason Jessica is interested in helping students navigate the college process and college is because she is among the first generation in her family to college. She is also available for subject tutoring in the arts."},
      ],
    };
      this.goToTutors = this.goToTutors.bind(this);
      this.goToSignIn = this.goToSignIn.bind(this);
      this.togglePromotionModal = this.togglePromotionModal.bind(this);
      this.currentEmail = localStorage.getItem(AppString.EMAIL);
      //this.sendMail = this.sendMail.bind(this);
  }

  goToTutors(item){
    this.props.history.push({
      pathname:(ROUTES.TUTORS),
      state:{
        modalOpened: true, 
          itema: item,
          promotionModalOpened: true,
      }
     });
    }

    goToSignIn() {
        this.props.history.push({
            pathname: (ROUTES.STUDENT_SIGN_UP),
            state: {
            }
        });
    }

    componentDidMount() {
        if (this.currentEmail == null || this.currentEmail == "") {
            //not logged in so open sign in modal
            this.setState({ promotionModalOpened: true })
        }
        else {
            this.setState({promotionModalOpened: false})
        }
    }

    togglePromotionModal() {
        this.setState({ promotionModalOpened: false })
    }

    /*sendMail() {
        window.Email.send({
            //neha gmail
            SecureToken: "130346f0-604f-46bc-b035-0f0c82089bba",
            To: "nesharma@stanford.edu",
            From: "nehasharma0499@gmail.com",
            Subject: "Collegiate Tutor Network: New Message",
            Body: "Hello, <br /> <br />  You have received a new message from " + "blah" + ". To view the message, please visit collegiatetutornetwork.com. <br /> <br />  Thank you, <br />  Collegiate Tutor Network Team"
        }).then(
            message => alert(message)
        );
        console.log("sent email")
    }*/

  render() {
    let itemList = this.state.items.map(item=>{
      return(
          <div className="card_tutors" key={item.id}>
              <button className="new_tutor_card_landing" onClick={() => this.goToTutors(item)}>
                
                <div className="tutor_profile_div">
                {item.id == 3 ? ( 
                      <img
                      className="tutor_profile_picture_opaque"
                      src={item.img}
                      alt="icon avatar"
                  />)  : 
                  <img
                      className="tutor_profile_picture_landing"
                      src={item.img}
                      alt="icon avatar"
                  /> } 
                </div>
                <div className="new_tutor_text">
                  <div id="name">{item.name}</div>
                  <div id="school">{item.school} {item.year}</div>
                  <hr class="divider_a"></hr>
                  <div className="major_line">
                    <div id="tag"> 
                      MAJOR
                    </div>
                    <div id="response">
                      {item.major}
                    </div>
                  </div>
                  <div className="subject_line">
                    <div id="tag">
                      SUBJECTS
                    </div>
                    <div id="response">
                      {item.subjects}
                    </div>
                  </div>
                  <div className="text_line">
                    <div id="tag">
                      LANGUAGES
                    </div>
                    <div id="response">
                      {item.languages}
                    </div>
                  </div>
                  <div className="text_line">
                    <div id="tag">
                      LOCATION
                    </div>
                    <div id="response">
                      {item.location}
                    </div>
                  </div>
                  <div className="text_line">
                    <div id="tag">
                      PRICE
                    </div>
                    <div id="response">
                      {item.price}
                    </div>
                  </div>
                </div>
              </button>

          </div>
      )
  })
    return (
    <div>
        <Modal className="modal_promotion"
            isOpen={this.state.promotionModalOpened}
            onRequestClose={this.togglePromotionModal}>
                <div className="modal_text_message">
                <div className="promo_text">Welcome! Use our new customer discount code for a <br></br><strong>free 3-month subscription</strong><br></br></div>
                <div className="promo_banner">TUTOR2022</div>
                <button className="button_promo"
                    onClick={e => this.goToSignIn()}>
                        Sign Up
                </button>
                <br></br>
                <br></br>
                Valid until February 14th, 2022. You may cancel at any time.
                </div>
        </Modal>

        <div className="abcd">
          {/*<button onClick={this.sendMail}> Send Mail </button>*/}
          <img className="background" src={require("./background_3.jpg")}></img>
          <div className="img_overlay">A group of students from top universities, <br></br>
          with a passion for teaching.</div>
        </div>
        <div className="abcd">
          <img className="background" src={require("./background_2.jpg")}></img>
          <div className="img_overlay">Connect directly with the ideal tutor <br></br>
          for your needs. </div>
        </div>
        <div className="abcd">
          <img className="background" src={require("./background_1.jpg")}></img>
          <div className="img_overlay">By students, <br></br>
          for students.</div>
        </div>

        <hr className="solid"></hr>
        <div className="section">
        <div className="title" id="tutors">Featured Tutors</div>
        <section className="card">
          {itemList}
        </section>
        </div>
        <hr className="solid"></hr>


        <div className="our_model">
          <div className="title">Our Model</div>
          <div className="model">
              
                <div className = "text_model_a"><strong><i>Not a traditional tutoring company</i></strong></div>
                <div className="text_model">
                Collegiate Tutor Network is a modern tutoring platform. We connect parents to top tutors across the country, without restrictions. Unlike traditional tutoring companies, we give parents the ability to hand-pick the perfect tutor for their student from our diverse roster of tutors. Parents then have the freedom to negotiate with the tutor and work together to craft the optimal learning experience for their student. 
                </div>
                
                <div className = "text_model_a"><strong><i>Tutors from elite universities</i></strong> </div>
                <div className="text_model">
                All of our tutors are current undergraduates or recent alumni at elite universities including Stanford, Harvard, and Columbia University. 
                </div>

                <div className = "text_model_a"><strong><i>By students, for students </i></strong></div>
                <div className="text_model">
                As current college students, our tutors were in the same position as your student not too long ago. This means that we understand the challenges that your student is experiencing and we know how to overcome them. 
                </div>

                <div className = "text_model_a"><strong><i>No commission</i></strong> </div>
                <div className="text_model">
                Elite tutoring companies charge an average of 50% commission. We don’t. This means that parents pay less and tutors make more.
                </div>
            </div>

        </div>

        <hr className="solid"></hr>
        <div className="section">
          <div className="title">Tutoring during COVID-19</div>
          <div className="text_a">All of our tutors offer virtual tutoring using video conferencing, in addition to various online teaching platforms. Our tutors understand the difficulties that come with online schooling, as they too have had their university classes made virtual. Online school lacks many key factors that are essential to a successful education. The online format reduces the student’s attention span and makes it more difficult for students to develop personal connections with instructors when they are in a large classroom setting. Our tutors can help remedy the disadvantages from online school by giving your students personalized lessons to ensure that they succeed despite the educational set-backs caused by COVID-19.
          </div>
        </div>
        <hr className="solid"></hr>


        {/*<div className="section">
          <div className="title">Meet Our Founders</div>
          <div className="founders">
            <div className="prof">
              <img className="img" src={require("./neha_profile.png")}></img>
              <div className="text">Neha Sharma</div>
            </div>
            <div className="prof">
              <img className="img" src={require("./bea_profile.png")}></img>
              <div className="text">Beatriz Yankelevich</div>
            </div>
            <div className="textfounders"> Neha and Beatriz, both seniors at Stanford University majoring in Computer Science and Engineering Physics, respectively, founded Collegiate Tutor Network with the goal of creating a more transparent and equitable tutoring platform that has the best interests of both the students and the tutors in mind. Neha and Beatriz have both worked as private tutors since high school, and are committed to applying their extensive tutoring experience towards creating the highest quality tutoring service possible. Please <a className="link_how_it_works" href={`mailto:collegiatetutornetwork@gmail.com`}>contact us</a> if you have any questions or comments, and we will be happy to personally address your concerns.
            </div>
          </div>
        </div>

        <hr className="solid_a"></hr>*/}
        

      </div>
    );
  }
}

export default withRouter(Landing);
