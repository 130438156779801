import React from 'react';
import './SubscriptionPolicy.css';



class SubscriptionPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //statesList: window.cs142models.statesModel(),
      returnStates: [],
      substring: '',
      inputValue: '',
      i: 100,
      r: 100,
      message: "Here is the alphabetically sorted list of all the ",
      buttonWasClicked: '',
    };
    this.init();
    this.handleChangeBoundNew = event => this.handleChangeMotto(event);
    this.handleChangeMotto = this.handleChangeMotto.bind(this);

    //console.log('window.cs142models.statesModel()', window.cs142models.statesModel());
  }

  init(){
    var tempArray = [];
    //tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
    var newTemp = this.state.returnStates;
    for(var i = 0; i < tempArray.length; i++){
      newTemp[i] = <li key={i}> {tempArray[i]} </li>;
      console.log('newTemp[i]', newTemp[i]);
      //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      //this.setState({returnStates: [...newTemp, <li key={i}> {tempArray[i]} </li>]});
      //this.setState({returnStates: this.state.returnStates.concat([<li key={i}> {tempArray[i]} </li>])})
    }
    this.setState({ returnStates: newTemp });
     console.log('newTemp', newTemp);
      console.log('userNamesList', this.state.returnStates);
  }

  handleChangeMotto(event){
    var substr = event.target.value;
    this.setState({ substring: event.target.value, i: this.state.statesList.length, r:this.state.returnStates.length });

    if(substr === ""){
      this.setState({ message: "Here is the alphabetically sorted list of all the" });
      //this.state.message = "Here is the alphabetically sorted list of all the ";
      var tempArray = [];
      var newTemp = this.state.returnStates;
      tempArray = this.state.statesList.sort((a, b) => a.localeCompare(b));
      for(var i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });

    }
    else{
      //var emptyArr = [];
      //this.setState({ returnStates: emptyArr });
      //this.state.returnStates = [];
      tempArray = [];
      for(i = 0; i < this.state.statesList.length; i++){
        if(this.state.statesList[i].toLowerCase().includes(substr.toLowerCase())){
            tempArray.push(this.state.statesList[i]);
        }
      }
      tempArray = tempArray.sort((a, b) => a.localeCompare(b));
      newTemp = [];
      //newTemp = this.state.returnStates;
      for(i = 0; i < tempArray.length; i++){
        newTemp[i] = <li key={i}> {tempArray[i]} </li>;
        //console.log('newTemp[i]', newTemp[i]);
        //this.state.returnStates[i] = <li key={i}> {tempArray[i]} </li>;
      }
      this.setState({ returnStates: newTemp });
     

      if(this.state.returnStates.length === 0){
        this.setState({ message: "There are no matches for " });
      }
      else{
        this.setState({ message: "Here is the alphabetically sorted list of all the " });
      }


    }
  }

  render() {
    return (
      <div>
        <div class="overall">
          <div class="header">Subscription Policy</div>
          <div class="text_terms">
          To be able to contact tutors, you must make an account and sign up for a monthly subscription. You have the option between an introductory subscription of $10 per month for a minimum of six months, or $8 per month for a minimum of twelve months. After the initial minimum subscription period, the fee recurs monthly at the same initial rate. You are required to pay the monthly fee while receiving lessons from a tutor contacted through Collegiate Tutor Network. You may cancel the subscription when the initial minimum subscription period has passed and when you are no longer receiving lessons from a tutor contacted through Collegiate Tutor Network. If you have cancelled your subscription and wish to contact a new tutor through Collegiate Tutor Network or to resume tutoring lessons with a tutor previously contacted through the site, you must enroll again in an introductory subscription, and the same cancellation policies apply.
          If for some reason you cannot find a tutor who fits your needs, contact us, and you may be eligible to cancel your subscription before the end of the initial minimum subscription period.
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionPolicy;
