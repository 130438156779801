const images = {
    ic_default_avatar: require('../../images/ic_default_avatar.png'),
    ic_input_file: require('../../images/ic_input_file.png'),
    ic_photo: require('../../images/ic_photo.png'),
    ic_sticker: require('../../images/ic_sticker.png'),
    ic_send: require('../../images/ic_send.png'),
    ic_wave_hand: require('../../images/ic_wave_hand.png'),
    close: require('../../images/close.png'),
    chat: require('../../images/chat.png'),
    notification: require('../../images/notification.png'),

    josh_nkoy: require('../../images/JoshNkoyHeadshot.jpg'),
    farouk_grissom: require('../../images/Farouk_Grissom_headshot.jpg'),
    faiza_manoor: require('../../images/Faiza_Manoor_headshot.jpg'),
    joshua_schumacher: require('../../images/Joshua_Schumacher_headshot.jpg'),
    adam_pearl: require('../../images/Adam_Pearl-Headshot.jpg'),
    liam_christensen: require('../../images/Liam Christensen.jpeg'),
    skya_theobald: require('../../images/SkyaTheobald.jpg'),

    grace_jiang: require('../../images/Grace Jiang.jpg'),
    jessica_seng: require('../../images/Jessica Seng.jpg'),
    jialin_zhuo: require('../../images/Jialin Zhuo.JPG'),
    mauricio_tellez_sanchez: require('../../images/Mauricio Tellez-Sanchez.png'),
    nathaniel_stuart: require('../../images/Nathaniel Stuart.jpg'),
    omar_enrique_ramos_escoto: require('../../images/Omar Escoto.jpg'),
    shiriel_king_abramson: require('../../images/Shiriel Abramson.JPG'),
    talia_trepte: require('../../images/Talia Trepte.jpg'),
    toby_frager: require('../../images/Toby Frager.jpeg'),
    zoe_sonnenberg: require('../../images/ZSonnenberg_photo.jpg'),

    olivia_young: require('../../images/Olivia Young.jpg'),
    madeleine_bouton: require('../../images/Madeleine Bouton.jpeg'),
    brigitte_schmittlein: require('../../images/Brigitte Schmittlein.JPG'),
    luci_bresette: require('../../images/Lucille Bresette.JPG'),
    sidra_ijaz: require('../../images/Sidra Ijaz.jpg'),

    josie_brody: require('../../images/Josie Brody.png'),
    jennifer_bae: require('../../images/Jennifer Bae.png'),

    beatriz_yankelevich: require('../../images/BeatrizYankelevichHeadshot.JPG'),
    ethan_bogle: require('../../images/EthanBogle.png'),
    neha_sharma: require('../../images/neha_profile.png'),

    angela_casarez: require('../../images/Angela Casarez.png'),
}

export default images