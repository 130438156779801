import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Modal from 'react-modal';
import Terms from '../Terms';
import './../SignIn/SignIn.css';
import PayPalBtn from '../PayPalBtn'


const SignUpPage = () => (
  <div className="container_signin">
    <h1>Sign Up</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.state={
      modalOpened: false,
      accepted: false,
      ofAge: false,
      email: "",
      username: "",
      parentOrStudent: "", 
      subjects: "",
      medium: "",
      location: "",
      optionsState: '',
        option: '',
      promo: '',
      totalPrice: 50,
      paymentComplete: false,
      subscriptionLength: "3 month",
      subscriptionEndDate: null,
      subscriptionStartDate: null,
      planID: 'P-59J758334X066884HL7LPIHQ',
      invalid: false,
        started: false,
        source: '',
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.setOption = this.setOption.bind(this);
    this.checkInvalid = this.checkInvalid.bind(this);
  }
  
  checkInvalid(){
    //console.log("checking validity")
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
      accepted, 
      ofAge, 
      parentOrStudent, 
      subjects, 
      medium, 
      location,
      paymentComplete,
    } = this.state;

    if(passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      !ofAge ||
      !accepted ||
      username === '' ||
      parentOrStudent == '' ||
      subjects == '' || 
      medium == '' ||
      !paymentComplete){
        this.setState({invalid: true});
      }
      else{
        this.setState({invalid: false})
      }

  }

  componentDidMount() {
     window.onunhandledrejection = (err) => {
        console.log("error caught: " + err)
      }

    this.state.modalOpened = false;
    this.state.accepted = false;
    this.state.paymentComplete = false;
    this.state.subscriptionLength = "3 month";

    var d = new Date();
    this.state.subscriptionStartDate = d;
    d.setMonth(d.getMonth() + 3);
    this.state.subscriptionEndDate = d.toLocaleDateString();
  }

  paymentHandler = (details, data) => {
    //console.log(details, data);
    //console.log("SUBSCRIPTION LENGTH: " + this.state.subscriptionLength);
    if(details.status == "COMPLETED"){
      this.setState({ paymentComplete: true });
    }
    else{
      this.setState({ paymentComplete: false });
    }
  }

  setOption = (val) => {
    this.setState({ optionsState: val });
    if(val == 'A'){
      this.state.totalPrice = 50;
      this.setState({ subscriptionLength: "3 month" });
      //console.log("option A - $50");

      var d = new Date();
      d.setMonth(d.getMonth() + 3);
      //console.log("expiration date: " + 3);
      this.setState({ subscriptionEndDate: d.toLocaleDateString()})

      this.setState({planID: 'P-59J758334X066884HL7LPIHQ'});
      //console.log('P-59J758334X066884HL7LPIHQ');
    }
    else{
      this.state.totalPrice = 80;
      this.setState({ subscriptionLength: "6 month" });
      //console.log("option B - $80");

      var d = new Date();
      d.setMonth(d.getMonth() + 6);
      //console.log("expiration date: " + d.toLocaleDateString());
      this.setState({ subscriptionEndDate: d.toLocaleDateString()})

      this.setState({planID: 'P-127015498J103934RL7LPIRI'});
      //console.log('P-127015498J103934RL7LPIRI');
    }
  }

  onSubmit = event => {
    //console.log("payment complete?" + this.state.paymentComplete);

    event.preventDefault();
    const { username, email, passwordOne, isAdmin, parentOrStudent, subjects, medium, location, subscriptionLength, subscriptionEndDate, subscriptionStartDate, source } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        //added by me
        this.props.firebase.addNewStudentAccount(email, username, parentOrStudent, subjects, medium, location, subscriptionLength, subscriptionEndDate, subscriptionStartDate, source);
        ///////////   

        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
      this.setState({ started: true })

      if (event.target.value.toLowerCase() === "tutor2021") {
          this.setState({ paymentComplete: true })
          console.log("promo code accepted")
      }
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onAcceptTerms = event => {
    this.setState(prevState => ({accepted: !prevState.accepted}));
  };

  ofAgeCheckbox = event => {
    this.setState(prevState => ({ofAge: !prevState.ofAge}));
  };

  toggleModal() {
    this.setState(prevState => ({modalOpened: !prevState.modalOpened}));
  };


  paypalSubscribe = (data, actions) => {
    //console.log("trying to subscribe");
    //console.log(data);
    return actions.subscription.create({
    'plan_id': this.state.planID,
    }, () => {//console.log("CREATED PLAN")
  });
  };
  
  paypalOnError = (err) => {
    console.log("ERROR: " + err);
    console.log(err.name);
    console.log(err.details);
    console.log(err.err);
    console.log(err.message);
    console.log(err.env);
    if(err.message === "Order could not be captured"){
      this.setState({paymentComplete: true})
    }
    //if (err.indexOf("paypal.com/xoplatform/logger/api/logger") >= 0) {
      //console.log("false err"); // Ignore this error
    //}
  }
  
  paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    console.log("Paypal approved")
    //console.log(data.subscriptionID)
    this.setState({ paymentComplete: true });
    //console.log("payment complete?" + this.state.paymentComplete);
  };

  componentDidCatch(error, info) {
    console.log("caught error");
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log("error: " + error, info);
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
      accepted, 
      ofAge, 
      parentOrStudent, 
      subjects, 
      medium, 
      location,
        paymentComplete,
        promo,
      source,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      !ofAge ||
      !accepted ||
      username === '' ||
      parentOrStudent == '' ||
      subjects == '' || 
        medium == '' ||
        source == '' ||
      !paymentComplete;

    return (
      <div>
        <Modal className="modal_tutor" onClick={this.toggleModal}
            isOpen={this.state.modalOpened}
            onRequestClose={this.toggleModal}>
            <Terms/>
        </Modal>

        <form className="form" onSubmit={this.onSubmit}>
          <input className="input"
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder="Full Name"
          />
          {this.state.started && username == '' && <div className="small2" id='red'> Full name is required.</div>}
          <input className="input"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
          {this.state.started && email === '' && <div className="small2" id='red'> Email is required.</div>}
          <hr className="solid_short"></hr>
          <input className="input"
            name="parentOrStudent"
            value={parentOrStudent}
            onChange={this.onChange}
            type="text"
            placeholder="Are you a parent or student?"
          />
          {this.state.started && parentOrStudent === '' && <div className="small2" id='red'> Parent/student is required.</div>}
          <input className="input"
            name="subjects"
            value={subjects}
            onChange={this.onChange}
            type="text"
            placeholder="Subjects you require tutoring in"
          />
          {this.state.started && subjects === '' && <div className="small2" id='red'> Subjects is required.</div>}
          <input className="input"
            name="medium"
            value={medium}
            onChange={this.onChange}
            type="text"
            placeholder="Tutoring medium (in-person, virtual)"
          />
          {this.state.started && medium === '' && <div className="small2" id='red'> Medium is required.</div>}

          <input className="input"
            name="location"
            value={location}
            onChange={this.onChange}
            type="text"
            placeholder="If in-person tutoring, specify location"
          />

          {/*where did you find us from?*/}
          <input className="input"
            name="source"
            value={source}
            onChange={this.onChange}
            type="text"
            placeholder="How did you hear about us?"
          />
          {this.state.started && source === '' && <div className="small2" id='red'> This field is required.</div>}

          <hr className="solid_short"></hr>
          <input className="input"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
          <input className="input"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm Password"
          />
          {passwordOne !== passwordTwo && <div className="small2" id='red'> Passwords must match.</div>}

                {/*promo code*/}
                <hr className="solid_short"></hr>
                <h2>Enter Promo Code</h2>
                <input className="input"
                    name="promo"
                    value={promo}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Promo Code"
                />

                {/*paypal*/}
          {this.state.paymentComplete ? (
              <div> <strong>You have paid for a {this.state.subscriptionLength} subscription (until {this.state.subscriptionEndDate})!</strong></div>
            ) : (
              <div>
                <h2>Choose Plan and Payment</h2>
                <div id="red" className="small">Please make sure you have disabled ad-blockers. PayPal functionality will not work if they are enabled.</div>
                <select className="select" onChange={(event) => this.setOption(event.target.value)} 
                  value={this.state.optionsState}>
                  <option value="A">$50 (valid for 3 months)</option>
                  <option value="B">$80 (valid for 6 months)</option>
                </select>
                <option value={this.state.option.value} selected={this.state.optionsState == this.state.option.value}>{this.state.option.label}</option>
                <div className="paypal">
                  {/*<PayPalBtn 
                  amount = { this.state.totalPrice }
                  currency = {'USD'}
                  onSuccess={this.paymentHandler}/>*/}
                  <PayPalBtn
                    amount = { this.state.totalPrice }
                    currency = {'USD'}
                    createSubscription={this.paypalSubscribe.bind(this)}
                    onApprove={this.paypalOnApprove.bind(this)}
                    catchError={this.paypalOnError.bind(this)}
                    onError={this.paypalOnError.bind(this)}
                    onCancel={this.paypalOnError.bind(this)}
                  />
                </div>
              </div>
            )}

          <hr className="solid_short"></hr>
          <label>
            <input className="input"
              name="ofAge"
              type="checkbox"
              checked={ofAge}
              onChange={this.ofAgeCheckbox}
            />
            I confirm that I am 18 years of age or older. If you are younger than 18, please have a parent or guardian create an account for you.
            {this.state.started && !ofAge && <div className="small" id='red'> You must be 18 or older to sign up.</div>}
          </label>
          <label className="labellll">
            <input className="input"
              name="accepted"
              type="checkbox"
              checked={accepted}
              onChange={this.onAcceptTerms}
            />
            I agree to the Collegiate Tutor Network <button className="none" onClick={this.toggleModal}>Terms of Service</button>
            {this.state.started && !accepted && <div className="small" id='red'> Agreement to terms is required.</div>}
        </label>
          <div onClick={this.checkInvalid.bind(this)}>
          <button className="button_signin" disabled={isInvalid} id={isInvalid ? "invalid" : "valid"} type="submit" >
            Sign Up
          </button>
          </div>

          <div className="error"> {error && <p>{error.message}</p>} </div>
        </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link className="link_signin" to={ROUTES.SIGN_UP_INITIAL}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
