import React from 'react';
import './Blog.css';
import { compose } from 'recompose';
import {AppString} from '../chatConstants.js'
import Modal from 'react-modal';
import * as ROUTES from '../../constants/routes';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';


class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_visible:false,
      signInModalOpened: false,
    };
    this.currentEmail = localStorage.getItem(AppString.EMAIL);
}

viewBlog(){
  if(this.currentEmail == null || this.currentEmail == ""){
    //not logged in so open sign in modal
    this.setState({signInModalOpened: true});
  }
  else{
    this.setState({blog_visible: true});
  }
}

backToBlogs(){
  this.setState({blog_visible: false});
}

goToSignIn(item){
    this.props.history.push({
      pathname:(ROUTES.SIGN_IN),
      state:{
        modalOpened: true, 
        itema: item,
      }
     });
  }

  toggleSignInModal(){
    this.setState({signInModalOpened: false});
  }

  render() {
    return (
      <div>
        <Modal className="modal_message_signin"
          isOpen={this.state.signInModalOpened}
          onRequestClose={this.toggleSignInModal.bind(this)}>
          <div className="modal_text_message">
            You must be signed in to view blog posts. 
            <button className="button_signin_message"           
              onClick={e => this.goToSignIn()}>
              Log In/Sign Up
            </button>
          </div>
        </Modal>

        <div class="overall">
          {this.state.blog_visible &&
          <div className="blog_back" onClick={this.backToBlogs.bind(this)}>&larr; Back to Blogs</div>}
          <div className="blog_box">
            <div className="blog_title"> Your Comprehensive Guide to Standardized Exams, <br></br>Part 1: The SAT & ACT </div>
            <div className="blog_date"> 12/13/2020 </div>
            {!this.state.blog_visible &&
            <div>
              <div className="blog_preview">The college application process can be daunting. Students and parents have to keep track of multiple moving parts - essays, letters of recommendation, deadlines, etc. One of the most nerve-wracking parts of this process are the standardized exams. In this two-part series we tell you everything you need to know about these exams, starting with the SAT and the ACT. Next time we’ll talk about the SAT subject tests, AP exams, and the PSAT. </div>
              <button className="blog_button" onClick={this.viewBlog.bind(this)}> Read More </button>
            </div>
            }
            {this.state.blog_visible && 
            <div className="blog_text">
              <br></br>
              The college application process can be daunting. Students and parents have to keep track of multiple moving parts - essays, letters of recommendation, deadlines, etc. One of the most nerve-wracking parts of this process are the standardized exams. In this two-part series we tell you everything you need to know about these exams, starting with the SAT and the ACT. Next time we’ll talk about the SAT subject tests, AP exams, and the PSAT. 
              <br></br>
              <br></br>
              The SAT and the ACT are the college entrance exams. They are distinct exams offered by different companies: the SAT is administered by the College Board, which administers many other standardized exams (we’ll get into those next time) while the ACT is administered by ACT, Inc. (this is the only exam they administer). Most colleges require either the SAT or the ACT to apply. While these requirements have been waived this year due to the pandemic, they are likely to resume next year. 

              <br></br><br></br>
              <strong>The SAT</strong>
              <br></br>
              The SAT has four sections: three multiple choice sections - Reading, Writing and Language, and Math - and an optional essay section. The reading and writing/language sections are combined and scored from 200-800, and the math section is also scored from 200-800, for a total of 1600 (before 2016, each section was scored separately for a total of 2400). The essay is given a separate score from 2-8. The majority of the math problems come from geometry and algebra I, while a few come from trigonometry and algebra II. 

              <br></br><br></br>
              <strong>The ACT</strong>
              <br></br>
                The ACT has five sections: four multiple choice sections - English, Reading, Math, Science - and an optional essay section. The raw scores of each multiple choice section are equally weighted and combined to get a score out of 36. The essay is given a separate score from 2-12. The math section includes topics up to and included algebra II. The science section does not depend on knowledge of scientific facts; rather, it assesses the student’s scientific thinking skills - such as interpreting data, rejecting/accepting hypotheses, logically designing experiments, etc. 

              <br></br><br></br>
              <strong>Which one should I take?</strong>
              <br></br>
              Pretty much all colleges accept both the SAT and the ACT and do not have a preference,  so students should take whichever one on which they can achieve the highest score. We generally recommend focusing on just one exam so that you can maximize your score, instead of splitting time and resources between the two exams. Each exam caters differently to different kinds of test-takers. If your student is more STEM-oriented, they may do better on the ACT, due to the additional science section. We generally recommend that a student take one or two practice tests for each the SAT and the ACT to see which exam suits them better, then dedicate all their time to studying for that exam. 

              <br></br><br></br>
              <strong>Should I take it even if it’s optional?</strong>
              <br></br>
                More and more universities are making these exams technically optional; however, most universities still ‘recommend’ them and most applicants still take them. While universities claim that not taking the exam will not hurt your chances of admission, a good score can only help you. Our suggestion is that, as long as your student has sufficient time to study and get a good score, they should opt-in to taking it - this also applies to the optional essay section (keep in mind that some universities, such as the UC system, require the essay). 
              
                <br></br><br></br>
                <strong>When should I take it?</strong>
              <br></br>
                The SAT and the ACT are both offered almost every month. Check their websites for dates and locations. Most students take the exams either in the spring of junior year or fall of senior year. The important thing is that you give yourself enough time to take the exam multiple times and to study between exams. Most students improve their scores the second time. While you can technically take the SAT as many times as you want, and the ACT up to 12 times, a general rule of thumb is to not take either exam more than 3 times - some say that this is frowned upon by universities (however, depending on which universities you apply to, you may not have to report all of your scores - see below). The latest you can take the exam is the last sitting before the application deadline - this depends on whether you are applying early decision or regular decision (for the common app) or on the specific deadlines for state schools (such as the UC schools). However, keep in mind that if you take the last exam sitting, you might not get your scores back before the application deadline, which would mean that you would have to send your scores to universities without knowing your results. 

                <br></br><br></br>
                <strong>Reporting Scores</strong>
              <br></br>
                Score must generally be sent directly from the Collegeboard or ACT, inc. to the universities you apply to. You do this through their website. Some universities allow you to self-report scores, but if you are admitted you must then have the official scores sent to the university from the Collegeboard or ACT, inc.
                <br></br>
                <br></br>
                Every time you take the SAT, you can send your scores directly to four colleges for free, but only if you do so within nine days after taking the exam. This means that you will not have gotten your results back yet - so you will have to send them blindly. To send your SAT scores to more than four colleges or after you get your results, there is a fee (unless you are eligible for a waiver). The Collegeboard has the Score Choice option, which allows you to choose which scores to send, therefore you can send only your highest score. Some universities require that you send all of your scores, however, their policies generally state that they only consider the highest scores. You cannot send scores from a single section, you must send the entire score from a single sitting. Some colleges use “superscoring”, where they only consider the highest score of each section from all sittings - in this case it may be to your benefit to send in all your scores. Check each university’s application website for their specific policies. Generally, universities that require you send in all your scores will superscore your results. 
                <br></br>
                <br></br>
                The ACT has a similar policy. You can send your scores to four colleges for free from when you register for the exam until the Thursday after you take the exam. However, you will be doing so without knowing your results. They also offer the score choice option - however, again, some universities require that you send all your scores. Many colleges will also superscore your ACT.

                <br></br><br></br>
                <strong>How to study</strong>
              <br></br>
              The SAT and the ACT are all about your test-taking skills. They don’t really require memorization (with the exception of grammatical rules and some vocabulary), but rather test your ability to problem solve and think analytically. Most of the questions on the exam are all variations on a theme, and an experienced test taker can recognize these patterns to ace the exam. Consistent studying and timed practice exams are crucial to success. We reccomend that you start studying your junior year, to give yourself the necessary time. You can’t cram for these exams - a minimum of three months studying before your first exam, and another month or two before taking it again, is necessary to ensure you get your best possible score. Many of our tutors are experienced SAT tutors who can guide you through this process and ensure your students' success.
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
//const condition = authUser => !!authUser;

/*export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Blog);*/